.cellogiftwrapImages {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    padding: 1vw;
    max-width: 65vw;
    margin: auto;
}


.cellopage-banner {
    width: 100%;
    height: 30vh;
    object-fit: cover;
}

.cellogiftwrapImages > div {
    flex: 1 1 calc(25% - 1vw); /* Four items per row with gap accounted */
    max-width: calc(25% - 1vw); /* Ensure consistent sizing */
    box-sizing: border-box; /* Include padding/border in size calculation */
    text-align: center; /* Center-align content if needed */
}