.teammates {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 50vw;
    margin: 0 auto; 
}

.teammates > * {
    flex: 1 1 calc(33.333% - 1.5vw); 
    max-width: calc(33.333% - 1.5vw); 
    box-sizing: border-box; 
}

.meetteam h2 {
    font-size: 2vw;
    color: #182047;
    margin-bottom: -1vh;
}

.meetteam p {
    color: #666666;
    font-size: 0.8vw;
}

.open-positions {
    max-width: 60vw;
}

.open-positions h2 {
    font-size: 2vw;
    color: #182047;
}

.open-positions p {
    color: #666666;
    font-size: 0.8vw;
}

.great-products {
    max-width: 60vw;
}

.great-products h2 {
    font-size: 2vw;
    color: #182047;
}

.great-products p {
    color: #666666;
    font-size: 0.8vw;
}

.open-positions-container {
    display: flex;
    justify-content: center;
}

.great-products-container {
    display: flex;
    justify-content: center;
}

.careers {
    max-width: 60vw;
}

.careers h2 {
    font-size: 2vw;
    color: #182047;
}

.careers p {
    color: #666666;
    font-size: 0.8vw;
}

.careers-container {
    display: flex;
    justify-content: center;
}
