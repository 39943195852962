.sustainability-image img {
    width: 25vw;
    margin-right: 2vw;
    
}

.sustainability-content img {
    width: 15vw;
    margin-top: -2vh;
}

.sustainability-container {
    font-family: 'Walkway';
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 65vw;
    margin: auto;
    margin-top: 10vh;
}

.sustainability-content {
    text-align: left;
    max-width: 20vw;
    
}

.sustainability-content h2 {
    font-size: 2vw;
    color: #182047;
}

.sustainability-content p {
    color: #666666;
    font-size: 0.8vw;
}

.productImageGroup > div {
    flex: 1 1 calc(25% - 1vw); /* Four items per row with gap accounted */
    max-width: calc(25% - 1vw); /* Ensure consistent sizing */
    box-sizing: border-box; /* Include padding/border in size calculation */
    text-align: center; /* Center-align content if needed */
}

.productImageGroup {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    padding: 1vw;
    max-width: 65vw;
    margin: auto;
}

