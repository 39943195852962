@font-face {
    font-family: 'Walkway';
    src: url('../../../Assets/Fonts/Walkway Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Walkway-bold';
    src: url('../../../Assets/Fonts/Walkway Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.teammate {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    text-align: left;
    margin-bottom: 5vh;
    font-family: 'Walkway';
}

.teammate img {
    max-width: 10vw; 
    height: auto; 
    object-fit: cover; 
}

.teammate-info {
    max-width: 15vw; 
}

.teammate-info p {
    font-size: 0.8vw;
    color: #666666;
}

.teammate-info h2 {
    font-size: 2vw;
    color: #182047;
    font-family: "Walkway-bold";
}

.role {
    font-size: 1vw;
    font-weight: 600;
    color: #313030;
    margin-top: -3vh;
    font-family: "Walkway-bold";
}

.teammate p {
    color: #808080;
}