@font-face {
    font-family: 'Walkway';
    src: url('../../../Assets/Fonts/Walkway Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.navbar {
    display: flex;
    font-family: 'Walkway';
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.logo {
    height: 6vw;
    margin-right: 15vw;
    margin-left: 5vw;
}

.nav-list {
    list-style: none;
    display: flex;
    gap: 2vw;
}

.nav-item {
    position: relative;
}

.nav-item a {
    text-decoration: none;
    color: #666666;
    font-size: 1vw;
    transition: color 0.3s ease;
    font-family: inherit;
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block; 
}

.dropdown-menu {
    position: absolute; 
    background-color: white; 
    list-style: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    max-width: 20vw; 
    min-width: 13vw;
    padding: 0;
    margin: 0;
}

.dropdown-item {
    font-size: 1vw;
    text-align: left;
}

.dropdown-item a {
    text-decoration: none;
    color: #333; 
    display: block; 
    transition: background-color 0.3s ease, color 0.3s ease;
    padding: 1vw;
    max-width: calc(100% - 2vw);
}


.nav-item a:hover {
    color: black; 
}


.dropdown-item a:hover {
    background-color: #444; 
    color: #fff; 
}

body[data-page="about-us"] .nav-item a[href="/about-us"] {
    color: black;
    font-weight: bold;
}

body[data-page="home"] .nav-item a[href="/"] {
    color: black;
    font-weight: bold;
}

body[data-page="team"] .nav-item a[href="/team"] {
    color: black;
    font-weight: bold;
}

body[data-page="sustainability"] .nav-item a[href="/sustainability"] {
    color: black;
    font-weight: bold;
}

body[data-page="contact-us"] .nav-item a[href="/contact-us"] {
    color: black;
    font-weight: bold;
} 
