@font-face {
    font-family: 'Walkway';
    src: url('../../Assets/Fonts/AbcPrint.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AbcPrint';
    src: url('../../Assets/Fonts/AbcPrint.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


.Unique {
    font-family: 'AbcPrint';
    color:#182047;
    font-size: 10vw;
    margin-right: 2vw;
    /* font-weight: bold; */
}

.Designs {
    font-family: 'AbcPrint';
    color: #9ED4D4;
    font-size: 10vw;
}

.unique-designs {
    margin-top: -20vh;
    margin-bottom: -15vh;
    display: flex;
    justify-content: center;
}

.homepage-text1 {
    font-family: "AbcPrint";
    text-align: center;
    color: #182047;
    font-size: 1.5vw;
    letter-spacing: 0.1vw;
    max-width: 50vw;
    margin: 0 auto;
    display: flex;
}

.homepage-banner {
    margin-top: 2vh;
    width: 100%;
}

.icons {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 2vw; 
    justify-content: center;
    padding: 2vw;
    max-width: 80vw;
    margin: 0 auto;
    
}


.lifestyle-img {
    width: 50vw; 
    height: 100%; 
    max-height: 80vh;
    object-fit: cover; 
    display: block; 
}

.homepage-text div p:first-child {
    font-weight: bold; 
    color: #182047;
    font-family: "Walkway";
    font-size: 2vw;
}

.homepage-text div p {
    font-size: 1vw;
}

.text-item {
    max-width: 30vw;
}

.homepage-content { 
    display: flex;
    gap: 5vw; 
    max-width: 100%;
    font-family: "AbcPrint";
    background-color: #E5E5E5;
    text-align: left;
    align-items: center;
}

.companyIcon {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
    gap: 1vw; 
    max-width: 75vw; 
    margin: 0 auto; 
}

.companyIcon div {
    flex: 1 1 calc(25% - 20px); 
    max-width: calc(25% - 20px); 
    text-align: center; 
}

.companyIcon div:nth-last-child(-n+3) {
    flex: 1 1 calc(33.333% - 20px);
    max-width: calc(33.333% - 20px); 
}
