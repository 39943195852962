.partwareImage {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    padding: 1vw;
    max-width: 65vw;
    margin: auto;
}


.partywarepage-banner {
    width: 100%;
    height: 30vh;
    object-fit: cover;
}


.partwareImage > div {
    flex: 1 1 calc(25% - 1vw); 
    max-width: calc(25% - 1vw); 
    box-sizing: border-box; 
    text-align: center; 
}