@font-face {
    font-family: 'Walkway';
    src: url('../../Assets/Fonts/Walkway Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.contactus-body {
    font-family: 'Walkway';
}


.contact-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2vw;
}


.form-container {
    flex: 1;
    max-width: 30%;
    text-align: left;
}


.form-container h2 {
    font-size: 2vw;
    color:#182047;
}


.form-container p {
    margin-bottom: 1vw;
    font-size: 0.9vw;
    color: #555;
}


.form-container form {
    display: flex;
    flex-direction: column;
    gap: 1vw;
}


.form-message {
    height: 20vh;
}

form {
    display: flex;
    align-items: center;
}


form button {
    padding: 1vw 1vw;
    background-color: #666666;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9vw;
    width: 30.5vw;
}


form button:hover {
    background-color: rgb(187, 187, 187)
}


form input {
    width: 30vw;
    font-size: 0.9vw;
    border-width: thin;
    border-radius: 5px;
}


form textarea {
    width: 30vw;
    font-size: 0.9vw;
    border-width: thin;
    border-radius: 5px;
    resize: none;
}

.image-container img {
    height: 27vw;
    width: 100%;
    object-fit: cover;
}


.haveaproject {
    text-align: center;
    max-width: 65vw;
    margin: auto;
}

.haveaproject p {
    margin-bottom: 1vh;
}

.footer-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1vw;
}

.footer-item {
    max-width: 30%;
    text-align: left;
}

.haveaproject a {
    text-decoration: none;
    color: inherit;
}


.haveaproject h2 {
    color: #182047;
    font-size: 2vw;
}

.haveaproject p {
    color: #666666;
    font-size: 1vw;
}
