@font-face {
    font-family: 'Walkway';
    src: url('../../../Assets/Fonts/Walkway Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.footer {
    background-color: #f3f3f3; /* Light grey background */
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    font-size: 0.7vw;
    color: #333; /* Dark text color */
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 75vw;
    margin: 0 auto;
}

.footer-logo img {
    margin-right: 10vw;
    max-width: 15vw; /* Adjust logo size */
}

.footer-sections {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    text-align: left;
}

.footer-section h3 {
    font-size: 1vw;
    font-weight: bold;
    margin-bottom: 1vh;
    color: #182047; /* Dark blue */
}

.footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 1.8;
}

.footer-section address {
    font-style: normal;
    line-height: 1.6;
    margin-bottom: 10px;
}

.footer-section address a {
    text-decoration: none; 
    color: inherit; 
}

.footer-social a img {
    max-width: 1vw;
}

.footer-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 5vh;
    border-top: 1px solid #ddd; /* Thin border line */
}

.footer-bottom p {
    font-size: 1vw;
}

ul {
    list-style: none; /* Remove default bullets */
    font-size: 0.7vw;
}

ul li a {
    text-decoration: none; /* Remove underline */
    color: #333; /* Default text color */
    transition: color 0.3s ease; /* Smooth transition on hover */
}

ul li a:hover {
    color: #9ED4D4; /* Change to a darker color on hover */
}

