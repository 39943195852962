@font-face {
    font-family: 'Walkway';
    src: url('../../Assets/Fonts/AbcPrint.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.aboutus-container {
    display: flex;
    justify-content: center;
    margin-bottom: 5vh;
}

.about-us {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 55vw;
    margin-top: 5vh;
    text-align: left;
    font-family: 'Walkway';
}

.aboutus-content1 {
    max-width: 20vw;
}

.about-us h1 {
    color: #808080;
    font-size: 0.8vw;
}

.about-us h2 {
    font-size: 2vw;
    color: #182047;
}

.about-us p {
    color: #666666;
    font-size: 0.8vw;
}

.about-us-image1 img {
    width: 30vw; 
    height: 100%; 
    max-height: 80vh;
    object-fit: cover; 
    display: block; 
    margin-right: 2vw;
}

.about-us-image2 img {
    width: 30vw; 
    height: 100%; 
    max-height: 80vh;
    object-fit: cover; 
    display: block; 
    margin-left: 2vw;
}

.aboutus-content2 {
    max-width: 20vw;
}

.aboutus-content2 h2 {
    font-size: 2vw;
    color: #182047;
    text-align: left;
}

.aboutus-content2 p {
    text-align: left;
    color: #808080;
    font-size: 0.8vw;
}

.open-positions {
    font-family: 'Walkway';
    max-width: 60vw;
}

.open-positions h2 {
    font-size: 2vw;
    color: #182047;
}

.open-positions p {
    color: #666666;
    font-size: 0.8vw;
}

.great-products {
    font-family: 'Walkway';
    max-width: 60vw;
}

.great-products h2 {
    font-size: 2vw;
    color: #182047;
}

.great-products p {
    color: #666666;
    font-size: 0.8vw;
}

.open-positions-container {
    display: flex;
    justify-content: center;
}

.great-products-container {
    display: flex;
    justify-content: center;
}

.careers {
    font-family: 'Walkway';
    max-width: 60vw;
}

.careers h2 {
    font-size: 2vw;
    color: #182047;
}

.careers p {
    color: #666666;
    font-size: 0.8vw;
}

.careers-container {
    display: flex;
    justify-content: center;
}

/* .about-us {




.content4 {
    flex: 1;
    max-width: 50%;
    text-align: center;
    font-family: 'Walkway';
}

.content5 {
    flex: 1;
    max-width: 50%;
    text-align: center;
    font-family: 'Walkway';
}

.great-products {
    display: flex;
    justify-content: center;
}

.open-positions {
    display: flex;
    justify-content: center;
}


.image {
    max-width: 50%;
}

.image img {
    width: 70%;
    height: auto;
}



.our-mission{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-left: 170px;
}

.container {
    max-width: 95vw;
}



.about-us-banner img {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
    display: block; 
}


} */