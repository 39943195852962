.icon-item {
    text-align: center;
}

.icon-item img {
    width: 8vw; 
    height: auto; 
    transition: transform 0.3s ease;
}

.icon-item p {
    font-size: 1vw;
    font-family: 'AbcPrint';
}

.icon-item a {
    text-decoration: none;
    color: #666666;;
}


.icon-item img:hover {
    transform: scale(1.3); /* Slightly enlarge the image on hover */
}

